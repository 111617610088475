import React from 'react';
import {fromJS} from 'immutable';
import {Api} from '../../repository/api';
import moment from 'moment';
import { useApp } from '../../components/adminlayout/admin_provider';
import { useLocation } from 'react-router-dom';

interface DashboardContextType {
  queries: any,
  data: any,
  loading: boolean,
	sourceNames: string[],
	outletSelected?: { name?: string, subName?: string},
	onSourceNameFilterChanged: (names: any) => void,
  onDateChanged: (from: Date, to: Date, label: string) => void,
  onBrandChanged: (brands: any) => void,
  onReportViewTypeChanged: (name: string, value: string) => void,
}

const DashboardContext = React.createContext<DashboardContextType>(null!);

const useDashboard = () => {
	return React.useContext(DashboardContext);
};

function DashboardProvider({ children }: { children: React.ReactNode }) {

	const [queries, setQueries] = React.useState<any>(fromJS({}));
	const [data, setData] = React.useState<any>(fromJS({}));
	const [loading, setLoading] = React.useState(false);
	const [outletSelected, setOutletSelected] = React.useState<{name?: string, subName?: string}>();
	const [hub, setHub] = React.useState<any>(fromJS({}));
	const [sources, setSources] = React.useState<any>(fromJS([]));
	const [sourceNames, setSourceNames] = React.useState<string[]>([]);
	const location = useLocation();
	const app = useApp();
	

	const _fetchHub = async (hubId: string) => {
		setLoading(true);

		const response: any = await Api.fetchHub(hubId);
		const results = response.data.data;
		const hubData = fromJS(results);
		setHub(hubData);
		return hubData;
	}

	const _fetchSources = async (args: {hubId?: string, storeId?: string}) => {
		setLoading(true);

		const response: any = await Api.fetchSources(args);
		const results = response.data.data;
		const names: string[] = [];
		if (results) {
			for (const rs of results) {
				const existName = names.find((n) => n.toLowerCase() == rs.name.trim().toLowerCase())
				if (!existName) names.push(rs.name.trim());
			}
		}
		setSources(fromJS(results ?? []));
		setSourceNames(names);
	}

	const _getBrandFiltering = () => {
		return queries.get('brands')?.map((b: any) => {
			return {
				id: b._id,
				stores: b.stores?.map((s: any) => s._id)
			}
		}) ?? []
	}

	const _fetchOrderSummaries = async () => {
		try {
			const filterSources: string[] = [];
			if (queries.get('sources')) {
				for (const s of queries.get('sources')) {
					const selectedSources = sources.filter((item: any) => item.get('name').trim().toLowerCase() == s.toLowerCase())?.toJS();
					if (selectedSources && selectedSources.length > 0) {
						filterSources.push(...selectedSources.map((item: any) => item.name));
					}
				}
			}

			const rs = await Api.fetchOrderRevenueSummary({
				brands: _getBrandFiltering(), 
				sources: filterSources,
				from: queries.get('from').valueOf(), 
				to: queries.get('to').valueOf()
			});
			const results = rs.data.data;
			if (results.number_of_order > 0) {
				results.gross_revenue = results.item_revenue + results.delivery_fee
				results.item_per_order = results.total_item / results.number_of_order
				results.value_per_order = results.gross_revenue / results.number_of_order
			}
			return fromJS(results)
		} catch (e: any) {
			console.log(e)
			window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
			return null;
		}
	}

	const _refresh = async() => {
		setLoading(true);

		let results = data;

		const summaries: any = await _fetchOrderSummaries();
		results = results.updateIn(['order_summaries'], () => summaries);
		setData(results)

		setLoading(false)
	}

	const _prepareFilterForHubId = async (hubId: string) => {
		const hubData = await _fetchHub(hubId);
		const hubStores: any = hubData?.get('stores');
		if (!hubData || !hubStores || !app.brands) return null;
		const brandFilters: any[] = [];
		for (const hubStore of hubStores!) {
			const brand = app.brands!.find((b) => b._id == hubStore.getIn(['brand', '_id']))
			if (!brand) continue;
			let filterBrand = brandFilters.find((b: any) => b._id == brand._id);
			if (!filterBrand) {
				filterBrand = {
					...hubStore.get('brand').toJS(),
					stores: [hubStore.toJS()]
				}
				brandFilters.push(filterBrand);
			} else {
				filterBrand.stores.push(hubStore.toJS())
			}
		}
		console.log('->>hubData', hubData.get('name'))
		setOutletSelected({
			name: hubData.get('name') + '',
		})
		return queries.updateIn(['brands'], () => brandFilters)
	}

	const _prepareFilterForStoreId = async (storeId: string) => {
		if (!app.brands) return null;
		const brandFilters: any[] = [];
		for (const brand of app.brands) {
			if (!brand.stores) continue;
			const store = brand.stores.find((s: any) => s._id == storeId);
			if (!store) continue;
			brandFilters.push({
				_id: brand._id,
				name: brand.name,
				stores: [store]
			})
			break;
		}
		if (brandFilters.length > 0) {
			setOutletSelected({
				name: brandFilters[0].stores[0].name,
				subName: brandFilters[0].name
			})
		}
		return queries.updateIn(['brands'], () => brandFilters)
	}

	const _initData = async() => {
		if (!app.brands || app.brands.length < 0) {
			await app.fetchBrands();
		}
	}

	const _initQueries = async() => {
		const brands = app.brands;
		if (brands === undefined) return;
		if (!brands || brands.length < 1) {
			window.alert('Bạn cần liên hệ Admin để có thể truy cập')
			return;
		}

		const filters = location.state?.filters;
		if (!filters) {
			const currentTime = moment()
			setQueries(
				queries
					.updateIn(['brands'], () => app.brands)
					.updateIn(['from'], () => currentTime.clone().startOf('day'))
					.updateIn(['to'], () => currentTime.clone().endOf('day'))
			)
			return;
		}
		if (filters.hubId) {
			const newQueries: any = await _prepareFilterForHubId(filters.hubId);
			const currentTime = moment()
			setQueries(
				newQueries
					.updateIn(['from'], () => currentTime.clone().startOf('day'))
					.updateIn(['to'], () => currentTime.clone().endOf('day'))
			)
			_fetchSources({hubId: filters.hubId});
		}
		else if (filters.storeId) {
			const newQueries: any = await _prepareFilterForStoreId(filters.storeId);
			const currentTime = moment()
			setQueries(
				newQueries
					.updateIn(['from'], () => currentTime.clone().startOf('day'))
					.updateIn(['to'], () => currentTime.clone().endOf('day'))
			)
			_fetchSources({storeId: filters.storeId});
		}
	}

	const onSourceNameFilterChanged = (names: any) => {
		setQueries(
			queries.updateIn(['sources'], () => names)
		)
	}

	const onBrandChanged = (brands: any) => {
		setQueries(
			queries.updateIn(['brands'], () => brands)
		)
	}

	const onReportViewTypeChanged = (name: string, value: string) => {
		setData(data.updateIn([name], () => value))
	}

	const onDateChanged = (from: Date, to: Date, label: string) => {
		setQueries(
			queries.updateIn(['from'], () => from)
				.updateIn(['to'], () => to)
				.updateIn(['date_option'], () => label)
		)
	}

	React.useEffect(() => {
		if (data.size < 1 && !loading) {
			_initData();
		}
	}, []);

	React.useEffect(() => {
		if (queries.size > 1 || !app.brands || app.brands.length < 1) return;
		_initQueries();
	}, [app.brands]);

	React.useEffect(() => {
		if (queries.size < 1) return;
		_refresh()
	}, [queries]);

	const value = {
		queries,
		data,
		loading,
		sourceNames,
		outletSelected,
		onSourceNameFilterChanged,
		onBrandChanged,
		onReportViewTypeChanged,
		onDateChanged
	};

	return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
}

export default DashboardProvider;
export { useDashboard };
