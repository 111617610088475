export const numberUtils = {
	numberFormat: new Intl.NumberFormat('vi-VN'),
	format: (value: number) => {
		return numberUtils.numberFormat.format(value)
	},
	shortenNumber(n?: number | undefined) {
		if (!n) return n + '';
		if (n < 1e3) {
			if (Number.isInteger(n)) {
				return n + '';
			}
			return n.toFixed(1) + '';
		}
		if (n >= 1e3 && n < 1e6) return (n / 1e3).toFixed(1) + 'K';
		if (n >= 1e6 && n < 1e9) return (n / 1e6).toFixed(1) + 'M';
		if (n >= 1e9 && n < 1e12) return (n / 1e9).toFixed(1) + 'B';
		if (n >= 1e12) return (n / 1e12).toFixed(1) + 'T';
	}
	
};