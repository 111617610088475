import { format, getTime, formatDistanceToNow, getYear, isSameMinute, isSameDay, isSameMonth } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
	if (date instanceof Date && Number.isNaN(date.getTime())) {
		return '';
	}
	const fm = newFormat || 'dd MMM yyyy';

	return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
	if (date instanceof Date && Number.isNaN(date.getTime())) {
		return '';
	}
	const fm = newFormat || 'dd MMM yyyy p';

	return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
	if (date instanceof Date && Number.isNaN(date.getTime())) {
		return '';
	}
	return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
	if (date instanceof Date && Number.isNaN(date.getTime())) {
		return '';
	}
	return date
		? formatDistanceToNow(new Date(date), {
			addSuffix: true,
		})
		: '';
}

export function fTimeRange(start: InputValue, end: InputValue) {
	const startDate = start instanceof Date ? start : new Date(start ?? '');
	const endDate = end instanceof Date ? end : new Date(end ?? '');

	const currentYear = new Date().getFullYear();

	const startDateYear = startDate ? getYear(startDate) : null;

	const endDateYear = endDate ? getYear(endDate) : null;

	const isCurrentYear = currentYear === startDateYear && currentYear === endDateYear;

	const isSameMinutes =
    startDate && endDate ? isSameMinute(new Date(startDate), new Date(endDate)) : false;

	const isSameDays =
    startDate && endDate ? isSameDay(new Date(startDate), new Date(endDate)) : false;

	const isSameMonths =
    startDate && endDate ? isSameMonth(new Date(startDate), new Date(endDate)) : false;

	const time = 'HH:mm';
	if (isCurrentYear) {
		if (isSameMonths) {
			if (isSameDays) {
				if (isSameMinutes) {
					return fDate(endDate, `${time}, dd MMM yyyy`);  
				}
				return `${fDate(startDate,`${time}`)} - ${fDate(endDate, `${time}, dd MMM yyyy`)}`
			}
			return `${fDate(startDate,`${time}, dd`)} - ${fDate(endDate, `${time}, dd MMM yyyy`)}`
		}
		return `${fDate(startDate, `${time}, dd MMM`)} - ${fDate(endDate, `${time}, dd MMM yyyy`)}`
	}
	return `${fDate(startDate, `${time}, dd MMM yyyy`)} - ${fDate(endDate, `${time}, dd MMM yyyy`)}`
}