import React, {ChangeEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider, Sheet, Typography, TextField, Button } from '@mui/joy';
import { useAuth } from '../../components/auth/auth';

const Login = () => {
	const navigate = useNavigate();
	const [username, setUserName] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [requestState, setRequestState] = React.useState({loading: false, error: undefined});
	const auth = useAuth();

	const _onUserChanged = (e: ChangeEvent<HTMLInputElement>) => {
		setUserName(e.currentTarget.value);
	};

	function _onPasswordChanged(e: ChangeEvent<HTMLInputElement>) {
		setPassword(e.currentTarget.value);
	}

	const _onLoginClicked = async (e: any) => {
		e?.preventDefault();
		setRequestState({error: undefined, loading: true});

		try {
			await auth.signin(username, password);
			navigate('/', { replace: true });
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<CssVarsProvider>
			<Sheet sx={{mx: 3}}>
				<form onSubmit={_onLoginClicked}>
					<Sheet
						sx={{
							minWidth: 300,
							maxWidth: 500,
							mx: 'auto',
							my: 4,
							py: 3,
							px: 2,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							borderRadius: 'sm',
							boxShadow: 'sm',
						}}
						variant="outlined"
					>
						<div>
							<Typography level="h4" component="h1">
								<b>Xin Chào!</b>
							</Typography>
							<Typography level="body2">Đăng nhập để tiếp tục</Typography>
						</div>
						<TextField
							name="phone"
							type={'text'}
							placeholder="0968688888"
							label="Số điện thoại"
							value={username ?? ''}
							onChange={_onUserChanged}
						/>
						<TextField
							name="password"
							type="password"
							placeholder="password"
							label="Mật khẩu"
							value={password ?? ''}
							onChange={_onPasswordChanged}
						/>
						<Button 
							sx={{ mt: 1 }} 
							onClick={_onLoginClicked} 
							type={'submit'} 
							disabled={requestState.loading}
							loading={requestState.loading}>
							Đăng nhập
						</Button>
					</Sheet>
				</form>
			</Sheet>
		</CssVarsProvider>
	);
};

export default Login;