import { Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { numberUtils } from '../../../common/number_util';
import { useDashboard } from '../provider';
import AdvanceFilterSidebar from './advance-filter';
import CardValues from './card-values';
import TimeFilter from './time-filter';

const Views = () => {
	const provider = useDashboard();
	const [openFilter, setOpenFilter] = React.useState(false);

	const handleOpenFilter = () => {
		setOpenFilter(true);
	};

	const handleCloseFilter = () => {
		setOpenFilter(false);
	};

	console.log('provider.outletSelected', provider.outletSelected)

	return (
		<Container maxWidth="xl">
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={6}>
					<Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent={{xs:'center', sm:'flex-start'}}>
						<Stack direction="column" spacing={1} flexShrink={0} sx={{ mx: 1 }}>
							<Typography variant="h5" sx={{ mb: 0 }}>
								{
									provider.outletSelected?.name
								}
							</Typography>
							<Typography variant="caption" sx={{ mt: '0px !important', color: grey[500] }}>
								{
									provider.outletSelected?.subName
								}
							</Typography>
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent={{xs:'center', sm:'flex-end'}}>
						<Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
							<AdvanceFilterSidebar
								openFilter={openFilter}
								onOpenFilter={handleOpenFilter}
								onCloseFilter={handleCloseFilter}
							/>
							<TimeFilter />
						</Stack>
					</Stack>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={6} sm={6} md={3}>
					<CardValues 
						label={'ĐƠN THÀNH CÔNG'} 
						value={provider.data.getIn(['order_summaries', 'number_of_order'], 0)}
					/>
				</Grid>
				<Grid item xs={6} sm={6} md={3}>
					<CardValues 
						label={'DOANH THU'} 
						value={numberUtils.format(provider.data.getIn(['order_summaries', 'net_revenue'], 0)) + ' ₫'}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<CardValues 
						label={'TIỀN MẶT'} 
						value={numberUtils.format(provider.data.getIn(['order_summaries', 'net_cash'], 0)) + ' ₫'}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<CardValues 
						label={'CK/Online'} 
						value={numberUtils.format(provider.data.getIn(['order_summaries', 'net_online'], 0)) + ' ₫'}
					/>
				</Grid>
			</Grid>
		</Container>
	)
};

export default Views;