import * as React from 'react';
import {User} from '../../repository/model/User';

interface AuthContextType {
	user?: User;
	appView: boolean;
	signin: (user: string, password: string) => void;
	signout: () => void;
	updateToken: (token: string) => void;
	updateUser: (user: any) => void;
	updateAppView: (value: boolean) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const useAuth = () => {
	return React.useContext(AuthContext);
};


export { useAuth, AuthContext };