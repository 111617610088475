import React, {ChangeEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider, Sheet } from '@mui/joy';
import { useAuth } from '../../components/auth/auth';
import { CircularProgress } from '@mui/material';
import useQuery from '../../hooks/useQuery';

const AppOpen = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const queries = useQuery();
	const queryData: string | null = queries.get('data');

	React.useEffect(() => {
		try {
			const data: any = JSON.parse(decodeURIComponent(atob(queryData!)));
			const user = data.user;
			const filters = data.filters;
			auth.updateUser(user);
			auth.updateAppView(true);
			console.log(queryData);
			navigate('/dashboard/app', {
				state: {
					filters
				}
			});
		} catch(e) {
			console.log(e);
		}
	}, [queryData]);

	return (
		<CssVarsProvider>
			<Sheet sx={{mx: 3}}>
				<CircularProgress />
			</Sheet>
		</CssVarsProvider>
	);
};

export default AppOpen;