import PropTypes from 'prop-types';
import React, { memo } from 'react';
// @mui
import { Box } from '@mui/material';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';

function Scrollbar(props: { children: JSX.Element|JSX.Element[], sx: object }) {
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

	if (isMobile) {
		return (
			<Box sx={{ overflowX: 'auto', ...props.sx }}>
				{props.children}
			</Box>
		);
	}

	return (
		<StyledRootScrollbar>
			<StyledScrollbar timeout={500} clickOnTrack={false} sx={props.sx}>
				{props.children}
			</StyledScrollbar>
		</StyledRootScrollbar>
	);
}

export default memo(Scrollbar);
