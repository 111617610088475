import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AuthProvider from './components/auth/auth_provider';
import Login from './page/Login';
import RequireAuth from './components/auth/auth_require';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';
import AppProvider from './components/adminlayout/admin_provider';
import ThemeProvider from './theme';
import DashboardPage from './page/Dashboard';
import AppOpen from './page/AppOpen/app-open';
import AdminLayout from './components/adminlayout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const globalProviders = [
	RequireAuth, AppProvider
].reverse()
let AdminElement = <AdminLayout />;
for (const Provider of globalProviders) {
	AdminElement = <Provider>{AdminElement}</Provider>
}

root.render(
	<BrowserRouter>
		<StyledEngineProvider injectFirst>
			<ThemeProvider>
				<AuthProvider>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/app-open" element={<AppOpen />} />
						<Route element={AdminElement}>
							<Route path='/*' element={<Navigate to="/dashboard/app" replace={true} />}/>
							<Route path='/dashboard/app' element={<DashboardPage/>}/>
						</Route>
					</Routes>
				</AuthProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</BrowserRouter>
);
reportWebVitals();
