import React from 'react';
// @mui
import {
	Paper,
	Stack,
	Dialog,
	Button,
	DialogTitle,
	DialogActions,
	DialogContent,
	FormHelperText,
} from '@mui/material';
import { DatePicker, DateCalendar, DateTimePicker } from '@mui/x-date-pickers';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import { DateRangePickerProps } from './types';

// ----------------------------------------------------------------------

export default function DateRangePicker({
	title = 'Chọn ngày',
	variant = 'input',
	isDateTime = false,
	maxDate,
	//
	startDate,
	endDate,
	//
	onChangeStartDate,
	onChangeEndDate,
	//
	open,
	onClose,
	onApply,
	//
	isError,
}: DateRangePickerProps) {
	const isDesktop = useResponsive('up', 'md');

	const isCalendarView = variant === 'calendar';

	return (
		<Dialog
			fullWidth
			maxWidth={isCalendarView ? false : 'xs'}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					...(isCalendarView && {
						maxWidth: 720,
					}),
				},
			}}
		>
			<DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

			<DialogContent
				sx={{
					...(isCalendarView &&
            isDesktop && {
						overflow: 'unset',
					}),
				}}
			>
				<Stack
					spacing={isCalendarView ? 3 : 2}
					direction={isCalendarView && isDesktop ? 'row' : 'column'}
					justifyContent="center"
					sx={{
						pt: 1,
						'& .MuiCalendarPicker-root': {
							...(!isDesktop && {
								width: 'auto',
							}),
						},
					}}
				>
					{isCalendarView ? (
						<>
							<Paper
								variant="outlined"
								sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
							>
								<DateCalendar 
									value={startDate} 
									maxDate={maxDate}
									onChange={onChangeStartDate} />
							</Paper>

							<Paper
								variant="outlined"
								sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
							>
								<DateCalendar 
									value={endDate} 
									maxDate={maxDate}
									onChange={onChangeEndDate} />
							</Paper>
						</>
					) : (
						<>
							{
								isDateTime ? 
									<>
										<DateTimePicker
											label="Từ"
											ampm={false}
											maxDate={maxDate}
											value={startDate}
											onChange={onChangeStartDate}
										/>
										<DateTimePicker
											label="Đến"
											ampm={false}
											maxDate={maxDate}
											value={endDate}
											onChange={onChangeEndDate}
										/>
									</>
									:
									<>
										<DatePicker
											label="Từ ngày"
											maxDate={maxDate}
											value={startDate}
											onChange={onChangeStartDate}
										/>
										<DatePicker
											label="Đến ngày"
											maxDate={maxDate}
											value={endDate}
											onChange={onChangeEndDate}
										/>
									</>
							}
						</>
					)}
				</Stack>

				{isError && (
					<FormHelperText error sx={{ px: 2 }}>
            Đến ngày phải sau Từ ngày
					</FormHelperText>
				)}
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="inherit" onClick={onClose}>
          Đóng
				</Button>

				<Button disabled={isError} variant="contained" onClick={onApply}>
          Áp dụng
				</Button>
			</DialogActions>
		</Dialog>
	);
}
