import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

interface Props {
	label: string;
	value: any;
}

const CardValues = (props: Props) => {
	return (
		<Card
			sx={{ height: '100%' }}>
			<CardContent>
				<Grid
					container
					spacing={3}
					sx={{ justifyContent: 'space-between' }}>
					<Grid item>
						<Typography
							color="textSecondary"
							gutterBottom
							variant="overline"
						>
							{props.label}
						</Typography>
						<Typography
							color="textPrimary"
							variant="h4"
						>
							{props.value}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
};

export default CardValues;