import { AxiosResponse } from 'axios';
import { _get, _post, _postFile } from './request';

export const Api = {
	login(username: string, password: string): Promise<AxiosResponse<any>> {
		return _post('user/v1/users/login', {
			email: username,
			password
		});
	},
	fetchBrands(): Promise<AxiosResponse<any>> {
		return _get('brand/v1/brands')
	},
	fetchHub(hubId: string): Promise<AxiosResponse<any>> {
		return _get(`brand/admin/v1/hubs/${hubId}`)
	},
	fetchOrderRevenueSummary(args: {
		brands: any, 
		sources: string[],
		from: number, 
		to: number
	}): Promise<AxiosResponse<any>> {
		const filters: any = {
			brands: args.brands
		};
		if (args.sources && args.sources.length > 0) {
			filters['sources'] = args.sources;
		}
		return _post('bi/v1/report/summaries', {
			filters: filters,
			from: args.from,
			to: args.to
		})
	},
	fetchSources(args: {hubId?: string, storeId?: string}): Promise<AxiosResponse<any>> {
		let url = 'brand/pos/v1/sources';
		if (args.hubId) {
			url += `?hub=${args.hubId}`
		}
		else if (args.storeId) {
			url += `?store=${args.storeId}`
		}
		return _get(url)
	},
};
