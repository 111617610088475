import React, { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';

// ----------------------------------------------------------------------

interface Props {
  children: JSX.Element | JSX.Element[],
};

export default function ThemeProvider(props: Props) {
	const themeOptions: any = useMemo(
		() => ({
			palette,
			shape: { borderRadius: 6 },
			typography,
			shadows: shadows(),
			customShadows: customShadows(),
		}),
		[]
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />
				{props.children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
}
