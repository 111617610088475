import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
//
import navConfig from './config';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../scrollbar';
import Logo from '../../logo';
import NavSection from '../../nav-section';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

interface Props {
	openNav: boolean;
	onCloseNav: () => void;
};

export default function Nav(props: Props) {
	const { pathname } = useLocation();

	const isDesktop = useResponsive('up', 'lg');

	useEffect(() => {
		if (props.openNav) {
			props.onCloseNav();
		}
	}, [pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				'& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
			}}
		>
			<Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
				<Logo/>
			</Box>

			{/* <Box sx={{ mb: 5, mx: 2.5 }}>
				<Link underline="none">
					<StyledAccount>
						<Avatar src={''} alt="photoURL" />

						<Box sx={{ ml: 2 }}>
							<Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
								{'Hello'}
							</Typography>

							<Typography variant="body2" sx={{ color: 'text.secondary' }}>
								{'Admin'}
							</Typography>
						</Box>
					</StyledAccount>
				</Link>
			</Box> */}

			<NavSection data={navConfig} />

			<Box sx={{ flexGrow: 1 }} />
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV_WIDTH },
			}}
		>
			{isDesktop ? (
				<Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV_WIDTH,
							bgcolor: 'background.default',
							borderRightStyle: 'dashed',
						},
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={props.openNav}
					onClose={props.onCloseNav}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: { width: NAV_WIDTH },
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}
