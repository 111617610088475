import React from 'react';
// @mui
import {
	Box,
	Stack,
	Button,
	Drawer,
	Divider,
	FormGroup,
	IconButton,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { CloseOutlined, FilterAltOutlined, SearchOutlined } from '@mui/icons-material';
import Scrollbar from '../../../components/scrollbar';
import { useDashboard } from '../provider';
import { fromJS } from 'immutable';

interface Props {
  openFilter: boolean,
  onOpenFilter: () => void,
  onCloseFilter: () => void,
};

export default function AdvanceFilterSidebar(props: Props) {

	const provider = useDashboard();
	const [itemSourcesSelected, setItemSourcesSelected] = React.useState<any>(fromJS([]));

	const _onSourceOptionChanged = (e: any) => {
		const value = e.target.value;
		const nameIndex = itemSourcesSelected.findIndex((n: any) => n.toLowerCase() == value.trim().toLowerCase());
		if (nameIndex >= 0) {
			setItemSourcesSelected(
				itemSourcesSelected.delete(nameIndex)
			)
		} else {
			setItemSourcesSelected(
				itemSourcesSelected.push(value)
			)
		}
	}

	const _onSubmitClicked = () => {
		provider.onSourceNameFilterChanged(itemSourcesSelected);
		props.onCloseFilter();
	}

	React.useEffect(() => {
		const selectedSources = provider.queries.get('sources');
		if (!selectedSources || selectedSources.size < 1) {
			setItemSourcesSelected(fromJS([]))
		} else {
			setItemSourcesSelected(selectedSources);
		}
	}, [provider.queries.get('sources')]);

	return (
		<>
			<Button disableRipple color="inherit" endIcon={<FilterAltOutlined/>} onClick={props.onOpenFilter}>
        Bộ lọc&nbsp;
			</Button>

			<Drawer
				anchor="right"
				open={props.openFilter}
				onClose={props.onCloseFilter}
				PaperProps={{
					sx: { width: 280, border: 'none', overflow: 'hidden' },
				}}
			>
				<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
					<Typography variant="subtitle1" sx={{ ml: 1 }}>
					Bộ lọc
					</Typography>
					<IconButton onClick={props.onCloseFilter}>
						<CloseOutlined/>
					</IconButton>
				</Stack>

				<Divider />

				<Scrollbar sx={{}}>
					<Stack spacing={3} sx={{ p: 3 }}>
						<div>
							<Typography variant="subtitle1" gutterBottom>
                Nguồn đơn
							</Typography>
							<FormGroup>
								{
									provider.sourceNames?.map((name) => {
										const selected = itemSourcesSelected.findIndex((n: any) => n.toLowerCase() == name.trim().toLowerCase()) >= 0;
										return (
											<FormControlLabel 
												key={`filter_${name}`}
												control={<Checkbox checked={selected}/>} 
												label={name}
												value={name}
												onChange={_onSourceOptionChanged}/>
										)
									})
								}
							</FormGroup>
						</div>
					</Stack>
				</Scrollbar>

				<Box sx={{ p: 3 }}>
					<Button
						fullWidth
						size="large"
						type="submit"
						color="inherit"
						variant="contained"
						startIcon={<SearchOutlined/>}
						onClick={_onSubmitClicked}
					>
            Áp dụng
					</Button>
				</Box>
			</Drawer>
		</>
	);
}
