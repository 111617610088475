import axios from 'axios';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const VIET_QR_URL = process.env.REACT_APP_VIET_QR_API_URL;

export const appAxios = axios.create({
	baseURL: BASE_URL
});

interface RequestConfigType {
  token: string | undefined
}
export const RequestConfig: RequestConfigType = {
	token: undefined
};

appAxios.interceptors.request.use(
	function (configs) {
		if (RequestConfig.token) {
			if (!configs.headers) {
				configs.headers = {};
			}
			configs.headers['token'] = RequestConfig.token;
		}
		return configs;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const headers: any = () => {
	if (RequestConfig.token) {
		return {
			token: RequestConfig.token
		};
	}
	return {};
};

export const _get = async (url: string, params?: any) => {
	return await appAxios.get('/api/' + url, {
		headers: headers(),
		params
	});
};
export const _post = async (url: string, body?: any) => {
	return await appAxios.post('/api/' + url, body, {
		headers: headers()
	});
};
export const _put = async (url: string, body?: any) => {
	return await appAxios.put('/api/' + url, body, {
		headers: headers()
	});
};

export const _postFile = async (url: string, body?: any) => {
	const bodyFormData = new FormData();
	bodyFormData.append('image', body);
	return await appAxios.post('/api/' + url, bodyFormData, {
		headers: {
			token: RequestConfig.token,
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const _delete = async (url: string) => {
	return await appAxios.delete('/api/' + url, {
		headers: headers()
	});
};

export const _getVietQr = async (url: string) => {
	const response = await axios.get(VIET_QR_URL + url);
	return response;
};
