import React from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { bgBlur } from '../../../common/css_styles';
import { MenuOutlined } from '@mui/icons-material';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)((props: { theme: any }) => ({
	...bgBlur({ color: props.theme.palette.background.default }),
	position: props.theme.position,
	boxShadow: 'none',
	[props.theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${NAV_WIDTH + 1}px)`,
	},
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	minHeight: HEADER_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: HEADER_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

// ----------------------------------------------------------------------

interface HeaderProps {
	onOpenNav: () => void,
};

export default function Header(props: HeaderProps) {
	return (
		<StyledRoot>
			<StyledToolbar>
				<IconButton
					onClick={props.onOpenNav}
					sx={{
						mr: 1,
						color: 'text.primary',
						display: { lg: 'none' },
					}}
				>
					<MenuOutlined/>
				</IconButton>
				{/* <Searchbar /> */}
				<Box sx={{ flexGrow: 1 }} />

				<Stack
					direction="row"
					alignItems="center"
					spacing={{
						xs: 0.5,
						sm: 1,
					}}
				>
					<AccountPopover />
				</Stack>
			</StyledToolbar>
		</StyledRoot>
	);
}
