import React from 'react';
import Views from './components/views';
import DashboardProvider from './provider';

const DashboardPage = () => {
	return (
		<DashboardProvider>
			<Views/>
		</DashboardProvider>
	)
};

export default DashboardPage;