import * as React from 'react';
import {Api} from '../../repository/api';
import { useAuth } from '../auth/auth';

interface AppContextType {
  brands?: any[] | null | undefined,
	fetchBrands: () => Promise<void>
}

const AppContext = React.createContext<AppContextType>(null!);

const useApp = () => {
	return React.useContext(AppContext);
};

function AppProvider({ children }: { children: React.ReactNode }) {

	const [brands, setBrands] = React.useState<any[]>([]);

	const fetchBrands = async () => {
		try {
			const rs = await Api.fetchBrands();
			const brands = rs.data.data;
			setBrands(brands);
		} catch (e: any) {
			window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
			return;
		}
	}

	const auth = useAuth();

	React.useEffect(() => {
		console.log('->u', auth.user)
		if (!auth.user) return
		fetchBrands();
	}, [auth.user]);

	const value = {
		fetchBrands,
		brands: brands
	};

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppProvider;
export { useApp };