import React, { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography, Divider } from '@mui/material';
import { ArrowDropUpOutlined } from '@mui/icons-material';
import { useDashboard } from '../provider';
import moment from 'moment';
import DateRangePicker, { useDateRangePicker } from '../../../components/date-range-picker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

export default function TimeFilter() {
	const [open, setOpen] = useState(null);
	const {
		startDate,
		endDate,
		onChangeStartDate,
		onChangeEndDate,
		open: openPicker,
		onOpen: onOpenPicker,
		onClose: onClosePicker,
		onApply: onApplyPicker,
		isSelected: isSelectedValuePicker,
		isError,
		shortLabel,
		setStartDate,
		setEndDate,
	} = useDateRangePicker(false, new Date(), new Date());
	const provider = useDashboard();

	const onDateChanged = (from: Date, to: Date) => {
		provider.onDateChanged(from, to, shortLabel || '')
	}

	const handleOpen = (event: any) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	}

	const handleApply = () => {
		onDateChanged(startDate ?? new Date(), endDate ?? new Date());
		onApplyPicker();
		handleClose();
	}

	const handleChangeStartDate = (newValue: Date | null) => {
		onChangeStartDate(newValue);
	};

	const handleChangeEndDate = (newValue: Date | null) => {
		onChangeEndDate(newValue);
	};

	const setDateRange = (start: Date, end: Date) => {
		setStartDate?.(start)
		setEndDate?.(end)
		onDateChanged(start, end)
		handleClose();
	}

	const _handleToDayClicked = () => {
		const start: Date = moment().startOf('day').toDate()
		const end: Date = moment().endOf('day').toDate()
		setDateRange(start, end)
	}

	const _handleLastDayClicked = () => {
		const start: Date = moment().add(-1, 'day').startOf('day').toDate()
		const end: Date = moment().add(-1, 'day').endOf('day').toDate()
		setDateRange(start, end)
	}

	const _handleThisWeekClicked = () => {
		const start: Date = moment().startOf('isoWeek').toDate()
		const end: Date = moment().endOf('isoWeek').toDate()
		setDateRange(start, end)
	}

	const _handleLastWeekClicked = () => {
		const start: Date = moment().startOf('isoWeek').subtract(1, 'weeks').toDate()
		const end: Date = moment().endOf('isoWeek').subtract(1, 'weeks').toDate()
		setDateRange(start, end)
	}

	const _handleThisMonthClicked = () => {
		const start: Date = moment().startOf('month').toDate()
		const end: Date = moment().endOf('month').toDate()
		setDateRange(start, end)
	}

	const _handleLastMonthClicked = () => {
		const start: Date = moment().startOf('month').subtract(1, 'month').toDate()
		const end: Date = moment().endOf('month').subtract(1, 'month').toDate()
		setDateRange(start, end)
	}

	return (
		<>
			<Button
				color="inherit"
				disableRipple
				onClick={handleOpen}
				endIcon={<ArrowDropUpOutlined/>}
			>
        Thời gian:&nbsp;
				<Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
					{provider.queries.getIn(['date_option']) ?? 'Hôm nay'}
				</Typography>
			</Button>
			<Menu
				keepMounted
				anchorEl={open}
				open={Boolean(open)}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<MenuItem onClick={_handleToDayClicked}>
					Hôm nay
				</MenuItem>
				<MenuItem onClick={_handleLastDayClicked}>
					Hôm qua
				</MenuItem>
				<MenuItem onClick={_handleThisWeekClicked}>
					Tuần này
				</MenuItem>
				<MenuItem onClick={_handleLastWeekClicked}>
					Tuần trước
				</MenuItem>
				<MenuItem onClick={_handleThisMonthClicked}>
					Tháng này
				</MenuItem>
				<MenuItem onClick={_handleLastMonthClicked}>
					Tháng trước
				</MenuItem>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<MenuItem onClick={onOpenPicker}>
					Tùy chọn
				</MenuItem>
			</Menu>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateRangePicker
					variant="calendar"
					maxDate={new Date()}
					startDate={startDate}
					endDate={endDate}
					onChangeStartDate={handleChangeStartDate}
					onChangeEndDate={handleChangeEndDate}
					open={openPicker}
					onClose={handleClose}
					onApply={handleApply}
					isSelected={isSelectedValuePicker}
					isError={isError}
				/>
			</LocalizationProvider>
		</>
	);
}
