import { useState } from 'react';
import { isSameDay, isSameMonth, getYear, isBefore, isSameMinute } from 'date-fns';
// utils
//
import { DateRangePickerProps } from './types';
import { fDate } from '../../common/formatTime';

// ----------------------------------------------------------------------

type ReturnType = DateRangePickerProps;

export default function useDateRangePicker(isDateTime: boolean, start: Date | null, end: Date | null): ReturnType {
	const [open, setOpen] = useState(false);

	const [endDate, setEndDate] = useState(end);

	const [startDate, setStartDate] = useState(start);

	const isError =
    (startDate && endDate && isBefore(new Date(endDate), new Date(startDate))) || false;

	const currentYear = new Date().getFullYear();

	const startDateYear = startDate ? getYear(startDate) : null;

	const endDateYear = endDate ? getYear(endDate) : null;

	const isCurrentYear = currentYear === startDateYear && currentYear === endDateYear;

	const isSameMinutes =
    startDate && endDate ? isSameMinute(new Date(startDate), new Date(endDate)) : false;

	const isSameDays =
    startDate && endDate ? isSameDay(new Date(startDate), new Date(endDate)) : false;

	const isSameMonths =
    startDate && endDate ? isSameMonth(new Date(startDate), new Date(endDate)) : false;

	const standardLabel = `${fDate(startDate)} - ${fDate(endDate)}`;

	const getShortLabel = () => {
		const time = isDateTime ? 'HH:mm, ' : '';
		if (isCurrentYear) {
			if (isSameMonths) {
				if (isSameDays) {
					if (isSameMinutes) {
						return fDate(endDate, `${time}dd MMM yyyy`);  
					}
					return `${fDate(startDate,`${time}dd`)} - ${fDate(endDate, `${time}dd MMM yyyy`)}`
				}
				return `${fDate(startDate,`${time}dd`)} - ${fDate(endDate, `${time}dd MMM yyyy`)}`
			}
			return `${fDate(startDate, `${time}dd MMM`)} - ${fDate(endDate, `${time}dd MMM yyyy`)}`
		}
		return `${fDate(startDate, `${time}dd MMM yyyy`)} - ${fDate(endDate, `${time}dd MMM yyyy`)}`
	}

	const onChangeStartDate = (newValue: Date | null) => {
		setStartDate(newValue);
	};

	const onChangeEndDate = (newValue: Date | null) => {
		if (isError) {
			setEndDate(null);
		}
		setEndDate(newValue);
	};

	const onReset = () => {
		setStartDate(null);
		setEndDate(null);
	};

	return {
		startDate,
		endDate,
		onChangeStartDate,
		onChangeEndDate,
		//
		open,
		onOpen: () => setOpen(true),
		onClose: () => {
			setOpen(false)
		},
		onApply: () => {
			setOpen(false)
		},
		onReset,
		//
		isSelected: !!startDate && !!endDate,
		isError,
		//
		label: standardLabel || '',
		shortLabel: getShortLabel() || '',
		//
		setStartDate,
		setEndDate,
	};
}
