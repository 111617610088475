import React, { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import { Ref } from '..';

interface Props {
  src: string;
  sx: object;
}

const SvgColor = forwardRef<Ref, Props>((props, ref) => (
	<Box
		component="span"
		className="svg-color"
		ref={ref}
		sx={{
			width: 24,
			height: 24,
			display: 'inline-block',
			bgcolor: 'currentColor',
			mask: `url(${props.src}) no-repeat center / contain`,
			WebkitMask: `url(${props.src}) no-repeat center / contain`,
			...props.sx,
		}}
	/>
));

SvgColor.displayName = 'SvgColor';

export default SvgColor;
