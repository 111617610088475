import * as React from 'react';
import {Api} from '../../repository/api';
import {User} from '../../repository/model/User';
import {RequestConfig} from '../../repository/api/request';
import { AuthContext } from './auth';

function AuthProvider({ children }: { children: React.ReactNode }) {
	const u = localStorage.getItem('u');
	let cachedUser;
	try {
		if (u) {
			cachedUser = JSON.parse(u);
			RequestConfig.token = cachedUser.token;
		}
	} catch (e) { /* empty */ }

	const [user, setUser] = React.useState<User | undefined>(cachedUser);
	const [appView, setAppView] = React.useState<boolean>(false);

	const signin = async (username: string, password: string) => {
		let data;
		try {
			const rs = await Api.login(username, password);
			data = rs.data.data;
		} catch (e: any) {
			window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau');
			return;
		}
		setUser(data);
		RequestConfig.token = data.token;
		window.localStorage.setItem('u', JSON.stringify(data));
		return user;
	};

	const signout = async () => {
		RequestConfig.token = undefined;
		window.localStorage.removeItem('u');
	};

	const updateToken = (token: string) => {
		if (!user) return;
		RequestConfig.token = token;
		user.token = token;
		window.localStorage.setItem('u', JSON.stringify(user));
	};

	const updateUser = (data: any) => {
		if (!data) return;
		setUser(data);
		RequestConfig.token = data.token;
		window.localStorage.setItem('u', JSON.stringify(data));
	};

	const updateAppView = (value: boolean) => {
		setAppView(value);
	}

	const value = { user, signin, signout, updateToken, updateUser, updateAppView, appView };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;